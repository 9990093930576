.modal {
    composes:
        global(pointerEvents-none)
        global(position-absolute)
        global(pinAll)
        global(zi-modal);
}

.overlay {
    composes:
        global(bg-black)
        global(opacity-50)
        global(pinAll)
        global(position-absolute);
}

.table {
    composes:
        global(display-table)
        global(height-100)
        global(position-relative)
        global(width-100);
}

.tableCell {
    composes:
        global(display-tableCell)
        global(verticalAlign-middle)
        global(width-100);
}

.hint {
    composes:
        global(bg-black--transparent)
        global(borderRadius-l)
        global(c-white)
        global(centered)
        global(lineHeight-m)
        global(padding-s)
        global(textAlign-center);

    max-width: 260px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
