.close {
    composes:
        global(borderRadius-circle)
        global(cursor-pointer)
        global(link)
        global(position-absolute)
        global(transitionDuration-l)
        global(transitionTimingFunction-cv-easeInOutBack);

    width: 20px;
    height: 20px;
}

.hidden {
    opacity: 0;
    transform: scale(0);
}

.icon {
    composes:
        global(position-absolute)
        global(pinCenter);
}

.close--negative {
    composes:
        global(bg-negative)
        global(bg-red--d--hover)
        global(boxShadow-close)
        global(fill-white);

    top: -8px;
    right: -8px;
}

.close--light {
    composes:
        global(bg-marvel--hover)
        global(bg-smoke)
        global(fill-silver)
        global(fill-white--hover);

    top: -10px;
    right: -10px;
}
