.logo {
	display: block;
	width: 180px;
	height: 78px;
	margin: 0 auto 40px auto;
	animation: breathing 1s ease-in-out infinite normal;
}

@keyframes breathing {
	0% {
		transform: scale(0.9);
	}

	25% {
		 transform: scale(1);
	}

	60% {
		 transform: scale(0.9);
	}

	100% {
		 transform: scale(0.9);
	}
}
