:global {
  html,
  body {
    height: 100%;
    /* The style guide set this to white, for no real reason */
    background-color: transparent !important;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  /* Hide the Intercom widget */
  #intercom-container,
  #intercom-frame {
    display: none;
  }
}
