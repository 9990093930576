.navButton {
  composes:
    global(cursor-pointer)
    global(transitionProperty-all)
    global(transitionDuration-l)
    global(transitionTimingFunction-cb--scaleOut)
    global(position-absolute)
    global(display-inlineBlock);

  opacity: 0.3;
}

.navButton svg {
  opacity: 0.7;
}

.navButton:hover {
  opacity: 1;
}

.previousButton {
  composes:
    navButton
    global(previous-button);
}

.nextButton {
  composes:
    navButton
    global(next-button);
}

.wrapper {
  composes:
    global(position-fixed)
    global(overflow-hidden)
    global(verticallyCenter-absolute);

  z-index: 101;
  width: 50px;
  height: 180px;
}

.wrapperLeft .navButton {
  left: -30px;
}

.wrapperLeft:hover .navButton {
  left: 0;
}

.wrapperRight {
  right: 0;
}

.wrapperRight .navButton {
  right: -30px;
}

.wrapperRight:hover .navButton {
  right: 0;
}

.wrapperRightHandoffOpen .navButton {
  right: -30px;
}

.wrapperRightHandoffOpen:hover .navButton {
  right: 0;
}

.wrapperRightHandoffOpen {
  right: 300px;
}
