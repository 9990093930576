.scrollContainer {
    composes:
        global(position-absolute);
    -webkit-overflow-scrolling: touch;
}

.hotspotContainer {
    composes:
        global(position-relative)
        global(height-100);
}

.content {
    composes:
        global(position-absolute);
}

.hideScrollbars {
    scrollbar-width: none;
}

.hideScrollbars::-webkit-scrollbar {
    display: none;
}
