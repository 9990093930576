.container {
	position: relative;
	backface-visibility: hidden;
}

.scrollable {
	-webkit-overflow-scrolling: touch;
	overflow-y: scroll;
}

/* Note we have to specify some initial conditions in css when using
VelocityComponent, otherwise overlays can flash before the initial
value in 'animaiton' is applied (this is not a problem with
VelocityTransitionGroup as you can give an initial 'style' that is
applied *before* the animation starts */
.pop-enter,
.fade-enter,
.slidefade-enter
{
	opacity: 0;
}

.pushleft-enter,
.pushright-enter,
.slideup-enter,
.slidedown-enter,
.slide-right-enter,
.slide-left-enter {
	transform: translateX(100%);
}

.pop-enter {
	transform: scale(0);
}

.flow-enter {
	transform: translateX(100%);
}
.flowReverse-enter {
	transform: translateX(-100%);
}
