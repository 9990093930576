.position {
  composes:
    global(cursor-pointer)
    global(padding-m)
    global(pinAll)
    global(position-absolute)
    global(textAlign-center);
}

.table {
  composes:
    global(display-table)
    global(height-100)
    global(width-100);
}

.cell {
  composes:
    global(display-tableCell)
    global(verticalAlign-middle);
}

.box {
  composes:
    global(borderRadius-l)
    global(c-white)
    global(centered)
    global(transitionDuration-m)
    global(transitionTimingFunction-ease-in-out);

  max-width: 240px;
  background-color: rgba(0, 0, 0, 0.7);
}

.fontBig {
  composes:
    global(fontSize-s)
    global(lineHeight-s)
    global(padding-m);
}

.fontSmall {
  composes:
    global(fontSize-m)
    global(lineHeight-m)
    global(padding-s);
}

.position:hover .box {
  background-color: rgba(0, 0, 0, 0.8);
}

.icon {
  composes:
    global(fill-white)
    global(paddingBottom-s)
    global(paddingTop-xs);
}

.loading {
  composes:
    global(centered)
    global(is-loading--white)
    global(is-loading--xl)
    global(is-loading)
    global(position-relative);
}
