.screenWrap {
	position: absolute;
	overflow: hidden;
	width: 100%;
	height: 100%;
	animation-duration: 300ms;
	animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
}

.screenWrapPlacingAnnotation {
	cursor: crosshair;
}

.fade {
	animation-duration: 400ms;
}
.fade-current {
	animation-name: fadein;
}
@keyframes fadein {
	from { opacity: 0; }
	to { opacity: 1; }
}
.fade-reverse-previous {
	z-index: 100;
	animation-name: fadeout;
}
.fade-reverse-current {
	z-index: 99;
}
@keyframes fadeout {
	from { opacity: 1; }
	to { opacity: 0; }
}

.pushleft, .pushright {
	animation-timing-function: cubic-bezier(0.272, 0.224, 0.265, 0.975);
	animation-duration: 420ms;
}

.pushleft-previous {
	animation-name: pushouttoright;
	z-index: 100;
}
.pushleft-current {
	animation-name: pushinfromleftbelow;
	z-index: 99;
}
@keyframes pushouttoright {
    from { transform: translateX(0); }
    to { transform: translateX(100%); }
}
@keyframes pushinfromleftbelow {
    from { transform: translateX(-30%); opacity: 0.75;}
    to { transform: translateX(0);}
}

.pushright-previous {
	animation-name: pushouttoleftbelow;
}
.pushright-current {
	animation-name: pushinfromright;
}
@keyframes pushouttoleftbelow {
    from { transform: translateX(0);}
    to { transform: translateX(-30%);}
}
@keyframes pushinfromright {
    from { transform: translateX(100%); }
    to { transform: translateX(0); }
}
.pushleft-reverse-previous {
	animation-name: pushouttoleftbelow;
}
.pushleft-reverse-current {
	animation-name: pushinfromright;
}
.pushright-reverse-previous {
	animation-name: pushouttoright;
	z-index: 100;
}
.pushright-reverse-current {
	animation-name: pushinfromleftbelow;
	z-index: 99;
}

.slide, .slide-left {
	animation-timing-function: ease-in-out;
	animation-duration: 350ms;
}
.slide-previous {
	animation-name: slideouttoleft;
}
.slide-current {
	animation-name: slideinfromright;
}
.slide-reverse-previous {
	animation-name: slideouttoright;
}
.slide-reverse-current {
	/* transform: translateX(-100%); */
	animation-name: slideinfromleft;
}
@keyframes slideouttoleft {
    from { transform: translateX(0); }
    to { transform: translateX(-100%); }
}
@keyframes slideinfromright {
    from { transform: translateX(100%); }
    to { transform: translateX(0); }
}
@keyframes slideouttoright {
    from { transform: translateX(0); }
    to { transform: translateX(100%); }
}
@keyframes slideinfromleft {
    from { transform: translateX(-100%); }
    to { transform: translateX(0); }
}
.slide-left-previous {
	animation-name: slideouttoright;
}
.slide-left-current {
	animation-name: slideinfromleft;
}
.slide-left-reverse-previous {
	animation-name: slideouttoleft;
}
.slide-left-reverse-current {
	animation-name: slideinfromright;
}

/* Slide{down,up} */
.slidedown, .slideup {
	animation-duration: 450ms;
	animation-timing-function: cubic-bezier(0.272, 0.224, 0.265, 0.975);
}
.slidedown-previous, .slidedown-reverse-current, .slideup-reverse-previous, .slideup-current {
	box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
}
.slidedown-previous {
	animation-name: slideouttobottom;
	z-index: 100;
}
.slidedown-current {
	z-index: 99;
}
.slidedown-reverse-current {
	animation-name: slideinfrombottom;
}
@keyframes slideouttobottom {
    from { transform: translateY(0); }
    to { transform: translateY(100%); }
}
@keyframes slideinfrombottom {
    from { transform: translateY(100%); }
    to { transform: translateY(0); }
}

.slideup-current {
	animation-name: slideinfrombottom;
}
.slideup-reverse-previous {
	animation-name: slideouttobottom;
	z-index: 100;
}
.slideup-reverse-current {
	z-index: 99;
}

.pop {
	transform-origin: 50% 50%;
}
.pop-current, .pop-reverse-current {
	animation-duration: 400ms;
	animation-name: popin;
	animation-delay: 100ms;
	opacity: 0;
}
.pop-previous, .pop-reverse-previous {
	animation-duration: 300ms;
	animation-name: popout;
	opacity: 0;
}
@keyframes popin {
    from { transform: scale(0); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
}
@keyframes popout {
    from { transform: scale(1); opacity: 1; }
    to { transform: scale(0); opacity: 0; }
}

.flip {
	animation-duration: 400ms;
	animation-timing-function: ease-in-out;
	backface-visibility: hidden;
}
.flip-previous {
	animation-name: flipouttoleft;
}
.flip-reverse-previous {
	animation-name: flipouttoright;
}
.flip-current {
	animation-name: flipintoright;
}
.flip-reverse-current {
	animation-name: flipintoleft;
}
@keyframes flipouttoleft {
    from { transform: rotateY(0); opacity: 1;}
    to { transform: rotateY(-180deg); opacity: 0; }
}
@keyframes flipouttoright {
    from { transform: rotateY(0); opacity: 1;}
    to { transform: rotateY(180deg); opacity: 0; }
}
@keyframes flipintoleft {
    from { transform: rotateY(-180deg); opacity: 0; }
    to { transform: rotateY(0); opacity: 1;}
}
@keyframes flipintoright {
    from { transform: rotateY(180deg); opacity: 0;}
    to { transform: rotateY(0); opacity: 1;}
}

.flow {
	transform-origin: 50% 50%;
	box-shadow: 0 0 20px rgba(0,0,0,.4);
	animation-timing-function: ease;
}
.flow-current, .flow-reverse-current {
	animation-duration: 800ms;
	animation-delay: 400ms;
}
.flow-previous, flow-reverse-previous {
	animation-duration: 600ms;
}
.flow-previous {
	animation-name: flowouttoleft;
	transform: translateX(-100%);
}
.flow-current {
	opacity: 0;
	animation-name: flowinfromright;
}
.flow-reverse-previous {
	animation-name: flowouttoright;
	transform: translateX(100%);
}
.flow-reverse-current {
	opacity: 0;
	animation-name: flowinfromleft;
}
@keyframes flowouttoleft {
	0% { transform: translateX(0) scale(1); }
	60%, 70% { transform: translateX(0) scale(.7); }
	100% { transform: translateX(-100%) scale(.7); }
}
@keyframes flowinfromright {
	0% { transform: translateX(100%) scale(.7); opacity: 1 }
	30%, 40% { transform: translateX(0) scale(.7); opacity: 1 }
	100% { transform: translateX(0) scale(1); opacity: 1 }
}
@keyframes flowouttoright {
	0% { transform: translateX(0) scale(1); }
	60%, 70% { transform: translateX(0) scale(.7); }
	100% { transform:  translateX(100%) scale(.7); }
}
@keyframes flowinfromleft {
	0% { transform: translateX(-100%) scale(.7); opacity: 1 }
	30%, 40% { transform: translateX(0) scale(.7); opacity: 1 }
	100% { transform: translateX(0) scale(1); opacity: 1 }
}

.slidefade {
	animation-duration: 400ms;
}
.slidefade-previous {
	animation-name: slideouttoleft;
}
.slidefade-reverse-previous {
	animation-name: fadeout;
}

.slidefade-current {
	animation-name: fadein;
	opacity: 0;
}
.slidefade-reverse-current {
	animation-name: slideinfromleft;
}
