.modalBackground {
	composes:
		global(c-silver)
		global(fontWeight-3)
		global(height-100)
		global(overflow-auto)
		global(paddingTopBottom-m)
		global(pinTL)
		global(position-fixed)
		global(width-100)
		global(zi-800);

	-webkit-overflow-scrolling: touch;
  background-color: rgba(31, 45, 61, 0.75); /* $black */
}

.button {
	composes:
		global(bg-black)
		global(borderRadius-circle)
		global(fill-white)
		global(margin-xs);
}
