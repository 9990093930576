.deviceWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.device {
  position: relative;
  box-sizing: content-box;
  z-index: 2;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.deviceScreenBorder {
  width: 100%;
  height: 100%;
  position: relative;
  color: white;
  z-index: 100;
  display: block;
  border-radius: 2px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.7);
  overflow: hidden;
}

.ipadpro11 .deviceScreenBorder {
  border-radius: 12px;
  box-shadow: none;
}

.nexus_5 .deviceScreenBorder {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.7);
  border-radius: 0;
}

.iphonex .deviceScreenBorder {
  box-shadow: none;
}

/* Devices */

.iphone5c:before,
.iphone5:before,
.iphone6:before,
.iphone6plus:before,
.ipadpro11:before,
.ipad:before {
  content: '';
  width: calc(100% + 10px);
  height: 2%;
  position: absolute;
  z-index: 1;
  bottom: -1%;
  opacity: 0.5;
  left: -5px;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 67%,
    rgba(0, 0, 0, 0) 100%
  );
}

.iphonex-portrait {
  background-image: url(../assets/images/frames/iphonex.png);
}

.iphonex-landscape {
  background-image: url(../assets/images/frames/iphonex-landscape.png);
}

.iphonex .deviceScreenBorder:before {
  content: '';
  background-repeat: no-repeat;
  position: absolute;
  z-index: 200;
}

.iphonex-portrait .deviceScreenBorder:before {
  width: 60%;
  height: 3.8%;
  background-image: url(../assets/images/frames/iphonex-notch.png);
  background-position: top center;
  background-size: 100% auto;
  top: 0;
  left: 20%;
}

.iphonex-landscape .deviceScreenBorder:before {
  height: 60%;
  width: 3.8%;
  background-image: url(../assets/images/frames/iphonex-notch-landscape.png);
  background-position: top center;
  background-size: 100% auto;
  left: 0;
  top: 20%;
}

.appl_watch_42-portrait {
  background-image: url(../assets/images/frames/apple-watch-42.png);
}

.ipad-portrait {
  background-image: url(../assets/images/frames/ipad.png);
}

.ipad-landscape {
  background-image: url(../assets/images/frames/ipad-landscape.png);
}

.ipad_pro_11-portrait {
  background-image: url(../assets/images/frames/ipad-pro-11.png);
}

.ipad_pro_11-landscape {
  background-image: url(../assets/images/frames/ipad-pro-11-landscape.png);
}

.iphone4s-portrait {
  background-image: url(../assets/images/frames/iphone4s.png);
}

.iphone4s-landscape {
  background-image: url(../assets/images/frames/iphone4s-landscape.png);
}

.iphone5c-portrait {
  background-image: url(../assets/images/frames/iphone5c.png);
}

.iphone5c-landscape {
  background-image: url(../assets/images/frames/iphone5c-landscape.png);
}

.iphone5-portrait {
  background-image: url(../assets/images/frames/iphone5s.png);
}

.iphone5-landscape {
  background-image: url(../assets/images/frames/iphone5s-landscape.png);
}

.iphone6-portrait {
  background-image: url(../assets/images/frames/iphone6.png);
}

.iphone6-landscape {
  background-image: url(../assets/images/frames/iphone6-landscape.png);
}

.iphone6plus-portrait {
  background-image: url(../assets/images/frames/iphone6plus.png);
}

.iphone6plus-landscape {
  background-image: url(../assets/images/frames/iphone6plus-landscape.png);
}

.pixel_4-portrait {
  background-image: url(../assets/images/frames/pixel4.png);
}

.pixel_4-landscape {
  background-image: url(../assets//images/frames/pixel4-landscape.png);
}

.nexus_5-portrait {
  background-image: url(../assets/images/frames/nexus5.png);
}

.nexus_5-landscape {
  background-image: url(../assets/images/frames/nexus5-landscape.png);
}

.lumia920-portrait {
  background-image: url(../assets/images/frames/lumia920.png);
}

.lumia920-landscape {
  background-image: url(../assets/images/frames/lumia920-landscape.png);
}
