.bottomBackground {
	position: absolute;
	bottom: 0;
	height: 50%;
	width: 100%;
}

.inner {
	margin: auto;
	height: 100%;
}
