.player {
  position: absolute;
	left: 0;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.flip {
	perspective: 1000px;
	transform-style: preserve-3d;
}	

.handoffMode {
	composes: global(bg-snow--d);
}
