.background {
	height: 4px;
	width: 80%;
	max-width: 120px;
	display: block;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	border-radius: 4px;
}

.foreground {
	position: absolute;
	top: 0;
	left: 0;
	width: 0%;
	display: block;
	height: 4px;
}

.companyColor {
	opacity: 0.8;
}
