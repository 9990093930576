.outer {
	composes:
	      global(position-absolute)
	      global(width-100)
	      global(height-100)
	      global(pointerEvents-none);
	top: 0;
}

.inner {
	composes:
	      global(position-relative)
 	      global(height-100);
}

.overlay {
	composes:
	      global(position-absolute);
	opacity: 0.4;
}
	
.row {
	border: 0;
	border-style: solid;
	composes:
		global(borderWidth-0)
		global(borderStyle-solid)
		global(width-100);
}

.column {
	composes:
	      global(height-100);
}	

.strokeRow:not(:first-child):not(:last-child) {
	border-top-width: 1px;
	border-bottom-width: 1px;
}

.strokeRow:first-child {
	border-bottom-width: 1px;
}

.strokeRow:last-child {
	border-top-width: 1px;
}
