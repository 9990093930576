.imgWrapper {
	overflow: hidden;
	position: relative;
}

.img {
	user-drag: none;
	user-select: none;
}

.hotspotLayerWrapper {
	position: absolute;
	top: 0;
	width: 100%;
}

.handoffMode {
	composes:
		global(boxShadow-distant)
		global(marginTop-xl);
}
