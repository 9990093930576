.madeWithMarvel {
	width: 119px;
	height: 26px;
	background: url(../assets/images/powered-by.png) center center no-repeat;
	background-size: 119px 26px;
	position: absolute;
	bottom: 4px;
	left: 50%;
	margin-left: -60px;
	text-indent: -9999px;
}
