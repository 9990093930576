.circle {
	composes:
			global(position-absolute)
			global(borderRadius-circle);

	animation-name: animateClick;
	opacity: 0;

	/* Has to stay the same as what's defined replay.js */
	animation-duration: 600ms;
}

.miss {
   composes: global(bg-negative);
}

.hit {
   composes: global(bg-marvel);
}

@keyframes animateClick {

  0% {
     transform: scale(1);
     opacity: 0.5;
  }

  100% {
     transform: scale(3);
     opacity: 0;
  }
}
