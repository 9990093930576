.image {
	position: absolute;
	background-size: 100%;
	background-repeat: no-repeat;
}

.hiddenImage {
	position: absolute;
	left: -5000px;
}

.annotating {
	cursor: crosshair;
}
