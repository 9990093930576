.label {
	composes:
		global(position-absolute)
		global(borderRadius-s)
		global(c-white)
		global(fontSize-xs)
		global(fontWeight-5)
		global(lineHeight-xs)
		global(pointerEvents-none);
		
	background: tomato;
	padding-left: 3px;
	padding-right: 3px;
	z-index: 1;
}

.centre {
	composes: global(pinCenter);
}

.centreVertical {
	left: 50%;
	transform: translateX(-50%);
}

.centreHorizontal {
	top: 50%;
	transform: translateY(-50%);
}
