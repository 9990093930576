.annotation {
	composes:
		global(borderColor-white)
		global(borderRadius-circle)
		global(borderStyle-solid)
		global(c-white)
		global(cursor-grab)
		global(fontSize-s)
		global(lineHeight-l)
		global(position-absolute)
		global(textAlign-center);

	width: 34px;
	height: 34px;
	letter-spacing: -0.5px;

	margin-left: -17px;
	margin-top: -17px;

	border-width: 3px;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.4);

	transition: transform 300ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
}

.annotation:hover,
.highlight,
.dragging {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.6);
}

.annotation:hover,
.highlight {
    transform: scale(1.2);
}

/*
 * Annotation inner
 */

.completed,
.annotationNumber {
	composes:
		global(borderRadius-circle)
		global(display-block)
		global(fill-white)
		global(position-absolute)
		global(pinAll);
}

.completedIcon {
	composes:
		global(display-block)
		global(position-absolute)
		global(pinCenter);

	width: 30px;
	height: 30px;
}

.annotationTick {
	composes:
		global(position-absolute)
		global(pinAll)
		global(bg-positive);
}

/*
 * Notification
 */

.notification {
	composes:
		global(bg-purple--l)
		global(borderColor-purple)
		global(opacity-0)
		global(pointerEvents-none)
		global(borderRadius-circle)
		global(borderStyle-solid)
		global(position-absolute)
		global(pinCenter);

	width: 100px;
	height: 100px;
	border-width: 2px;
}

/*
 * States
 */

.done {
	composes:
		global(opacity-80)
		global(bg-silver)
		global(bg-green--hover)
		global(opacity-100--hover);
}

.draggingDone, .highlightDone {
	composes:
		global(bg-green)
		global(opacity-100);
}

.normal {
	composes: global(bg-purple);
}

.dehighlight {
	composes: global(bg-silver);
}

.moveable {
	composes: global(cursor-move);
}

.hiddenForDrag {
	composes:
		global(display-none);
}

.new {
	animation-name: bounce;
	animation-duration: 300ms;
	animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);
}

@keyframes bounce {
	from { transform: scale(0); opacity: 0; }
	to { transform: scale(1); opacity: 1; }
}
