.project {
	padding-left: 0;
	composes: global(height-100)
			global(width-100);
}

.annotationMode {
	background-color: rgba(31,45,61,0.96);
}

.handoffMode {
	padding-right: 301px;
	composes: global(bg-snow);
}
