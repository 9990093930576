.element {
	composes:
		global(borderStyle-solid)
		global(borderWidth-1)
		global(position-absolute);
	border-color: transparent;
}

.handle {
	composes:
		global(position-absolute);
	width: 3px;
	height: 3px;
	border-radius: 100%;
	border: 1px solid tomato;
	background: white;
}

.slice {
	/* TODO */
	border: 1px dotted #cccccc;
}

.hovered {
	cursor: pointer;
	border-style: solid !important;
	border-color: cyan !important;
}

.selected {
	border-style: solid !important;
	border-color: tomato !important;
}
