.table {
	composes:
		global(breakPointM-display-table)
		global(breakPointM-height-100)
		global(height-auto);
}

.tablecell {
	composes:
		global(breakPointM-display-tableCell)
		global(breakPointM-verticalAlign-middle);
}

.pagewrap {
	composes:
		global(breakPointM-paddingTopBottom-xl)
		global(paddingTopBottom-l)
		global(pageWrap--s)
		global(pageWrap)
		global(textAlign-center);

	max-width: 440px;
}

.logo {
	composes:
		global(display-inlineBlock)
		global(fill-marvel)
		global(marginBottom-l);
}

.image {
	composes:
		global(centered)
		global(marginBottom-l)
		global(width-100);

	max-width: 400px;
}

.heading {
	composes:
		global(c-slate)
		global(fontSize-xxl)
		global(fontWeight-5)
		global(lineHeight-xxl)
		global(marginBottom-xs);
}

.subheading {
	composes:
		global(c-silver)
		global(fontSize-xl)
		global(fontWeight-3)
		global(lineHeight-xl);
}
