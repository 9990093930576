.hotspot {
	position: absolute;
	cursor: pointer;
	opacity: 0;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	box-shadow: inset 0px 0px 0px 2px rgb(0, 126, 193), 0 2px 4px 0 rgba(0,0,0,0.1);
	background-color: rgba(154, 220, 255, 0.6);
	border-radius: 2px;
	transition: all 200ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
}

.visibleHotspot {
	opacity: 1;
}
