.container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: #FFF;
}

.text {
	composes:
		global(c-silver);
}
