.touch {
  composes:
    global(borderRadius-circle);
    position: absolute;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    pointer-events: none;
    width: 35px;
    height: 35px;
    z-index: 4;
    animation-name: pulse;
    animation-duration: 600ms;
    opacity: 0;
    background-image: linear-gradient(-180deg, #FFFFFF 0%, #D8D8D8 100%);
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.46), inset 0 1px 3px 0 rgba(255,255,255,0.66);
}

@keyframes pulse {

  0% {
    transform: scale(1);
    opacity: 1.0;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }

}
